import React from "react";
import "./index.css";
import logo from "../../Assets/bblogo.png";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-upper-container">
        <div className="logo-container">
          <h1 className="logo-heading">BONNY BOBEN</h1>
          <img className="footer-logo" src={logo} />
        </div>
        <div>
          <h1 className="social-text">Socials</h1>
          <a
            className="icons linkedin"
            rel="noreferrer"
            href="https://www.linkedin.com/in/bonnyboben/"
            target="_blank"
          >
            <FaLinkedin />
          </a>
          <a
            className="icons github"
            rel="noreferrer"
            href="https://github.com/Lichi-Dev"
            target="_blank"
          >
            <FaGithub />
          </a>
          <a
            className="icons instagram"
            rel="noreferrer"
            href="https://www.instagram.com/_bb_7/"
            target="_blank"
          >
            <FaInstagram />
          </a>
          <a
            className="icons twitter"
            rel="noreferrer"
            href="https://twitter.com/bonnybobencr7"
            target="_blank"
          >
            <FaTwitter />
          </a>
        </div>
      </div>
      <div className="partition"></div>
      <h1 className="copyright-heading">
        © Copyright 2023. Made by Bonny Boben
      </h1>
    </div>
  );
}

export default Footer;

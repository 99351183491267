import React from "react";
import profile from "../../Assets/profile.jpg";
import "./index.css";
import { motion } from "framer-motion";
import contact from "../../Assets/contact.png";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { HashLink as Link } from "react-router-hash-link";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

function Hero() {
  const [text] = useTypewriter({
    words: [
      "Frontend Developer.",
      "Quick Learner.",
      "Photographer.",
      "Football Fan.",
    ],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });
  return (
    <section className="hero-container" id="home">
      <img src="../../Assets/herobg2.jpg" style={{ display: "none" }} />
      <motion.h1
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="hero-heading"
      >
        HI, I'M BONNY <span className="surname">BOBEN</span>.
      </motion.h1>
      <motion.img
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        src={profile}
        className="profile-image"
      />
      {/* <motion.p
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="hero-description"
      >
        I am a <span className="surname">frontend</span> developer based in
        Delhi. I <span className="surname">enjoy</span> creating websites and
        applications that are both{" "}
        <span className="surname">aesthetically</span> pleasing and
        user-friendly. I am always <span className="surname">eager</span> to
        learn new technologies and <span className="surname">improve</span> my
        skills.
      </motion.p> */}
      <Link style={{ textDecoration: "none" }} to="#contact">
        <motion.img
          animate={{ rotate: 360 }}
          transition={{ ease: "linear", duration: 7, repeat: Infinity }}
          className="contact-logo"
          src={contact}
        />
      </Link>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="side-bar-container"
      >
        <div className="side-bar-section">
          <a
            className="icon linkedin"
            rel="noreferrer"
            href="https://www.linkedin.com/in/bonnyboben/"
            target="_blank"
          >
            <FaLinkedin />
          </a>
          <a
            className="icon github"
            rel="noreferrer"
            href="https://github.com/Lichi-Dev"
            target="_blank"
          >
            <FaGithub />
          </a>
          <a
            className="icon instagram"
            rel="noreferrer"
            href="https://www.instagram.com/_bb_7/"
            target="_blank"
          >
            <FaInstagram />
          </a>
          <a
            className="icon twitter"
            rel="noreferrer"
            href="https://twitter.com/bonnybobencr7"
            target="_blank"
          >
            <FaTwitter />
          </a>
        </div>
      </motion.div>
      <motion.h1
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="hero-description"
      >
        I'm a <span style={{ color: "#ffbe2e" }}>{text}</span>
        <span>
          <Cursor cursorStyle="|" />
        </span>
      </motion.h1>
    </section>
  );
}

export default Hero;

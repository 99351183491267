import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useToasts } from "react-toast-notifications";
import "./index.css";

export const Contact = () => {
  const form = useRef();
  const { addToast } = useToasts();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (name.length == 0 || email.length == 0 || message.length == 0) {
      setError(true);
    } else {
      emailjs
        .sendForm(
          "service_nqrtfuc",
          "template_m7e0988",
          form.current,
          "R7knoIhPlsT_aC0M_"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
            setName("");
            setEmail("");
            setMessage("");
            setError(false);
            addToast("Message Sent Successfully", {
              appearance: "success",
              autoDismiss: true,
            });
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <section className="contact-container" id="contact">
      <h1 className="education-heading">Contact Me</h1>
      <p className="contact-para">
        If you got any questions, please do not hesitate to drop me a message.
      </p>
      <form className="form-container" ref={form} onSubmit={sendEmail}>
        <br />
        <input
          onChange={(e) => setName(e.target.value)}
          type="text"
          name="user_name"
          value={name}
          placeholder="Name"
          className="name-input"
        />

        {error && name.length <= 0 ? (
          <div className="error-container">
            <label className="error-message">* Name can't be empty</label>
          </div>
        ) : (
          ""
        )}

        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name="user_email"
          value={email}
          placeholder="Email"
          className="name-input"
        />
        {error && email.length <= 0 ? (
          <div className="error-container">
            <label className="error-message">* Email can't be empty</label>
          </div>
        ) : (
          ""
        )}

        <textarea
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          name="message"
          placeholder="Message"
          className="message-input"
        />
        {error && message.length <= 0 ? (
          <div className="error-container">
            <label className="error-message">* Message can't be empty</label>
          </div>
        ) : (
          ""
        )}
        <input className="button-contact" type="submit" value="Submit" />
      </form>
    </section>
  );
};

import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import html from "../../Assets/html.png";
import css from "../../Assets/css.png";
import js from "../../Assets/js.png";
import git from "../../Assets/git.png";
import github from "../../Assets/github.png";
import figma from "../../Assets/figma.png";
import node from "../../Assets/node.png";
import python from "../../Assets/python.png";
import react from "../../Assets/react.png";
import mysql from "../../Assets/mysql.png";
import nextjs from "../../Assets/next.png";
import tailwind from "../../Assets/tailwind.png";
import Skill from "../Skill";

const skills = [html, css, js, css, css, css, css, css];

function About() {
  return (
    <section className="about-container" id="about">
      <h1 className="about-heading">ABOUT ME</h1>
      <div className="about-sub-container">
        <div className="get-to-know-me-container">
          <h1 className="get-to-know-me-heading">Get to know me!</h1>
          <p className="get-to-know-me-para">
            I'm a Fullstack developer. I have experience in creating and
            maintaining websites and applications using various technologies,
            such as HTML, CSS, JavaScript, Node, Express, Nextjs and React. I
            enjoy learning new skills and exploring new challenges in the field
            of web development. One of my passions is photography, which I use
            as a creative outlet and a way to capture the beauty of the world. I
            also love playing football and watching matches of my favorite
            teams.
          </p>
        </div>
        <div>
          <h1 className="get-to-know-me-heading">My Skills</h1>
          <div className="my-skill-container">
            <Skill img={html} />
            <Skill img={css} />
            <Skill img={js} />
            <Skill img={python} />
            <Skill img={node} />
            <Skill img={figma} />
            <Skill img={git} />
            <Skill img={github} />
            <Skill img={react} />
            <Skill img={mysql} />
            <Skill img={nextjs} />
            <Skill img={tailwind} />
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;

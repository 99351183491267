import React from "react";
import "./index.css";
import nxtwatch from "../../Assets/nxtwatch.png";
import fricipe from "../../Assets/fricipe.png";
import tasty from "../../Assets/tasty.png";
import wushu from "../../Assets/wushu.png";
import typing from "../../Assets/touch.png";
import todolist from "../../Assets/todolist.png";

function Project() {
  return (
    <section className="project-container" id="project">
      <h1 className="project-heading">PROJECTS</h1>
      <div className="project-sub-container">
        <h1 className="client-work-heading">Client Work</h1>
        <div className="project">
          <div className="project-description-container">
            <h1 className="project-sub-heading">Wushu India</h1>
            <p className="project-description-para">
              Developed a website for Wushu India. The website is a platform for
              wushu players and people who want to know more about Wushu. It
              provides information about the history, styles and techniques, as
              well as news,upcoming events, and resources for learning and
              practicing.
            </p>
            <a
              className="a-link"
              rel="noreferrer"
              href="https://www.indiawushu.com/"
              target="_blank"
            >
              <button className="button">View Site</button>
            </a>
          </div>
          <a
            className="a-link"
            rel="noreferrer"
            href="https://www.indiawushu.com/"
            target="_blank"
          >
            <img className="project-image" src={wushu} alt="project-image" />
          </a>
        </div>
        <h1 className="client-work-heading">Personal Project</h1>
        <div className="project">
          <a
            className="a-link"
            rel="noreferrer"
            href="https://nxtwatchbb.ccbp.tech/"
            target="_blank"
          >
            <img className="project-image" src={nxtwatch} alt="project-image" />
          </a>
          <div className="project-description-container">
            <h1 className="project-sub-heading">Nxt Watch</h1>
            <p className="project-description-para">
              Nxt Watch platform is a YouTube-inspired application where users
              can access various video categories, such as Trending, Gaming, and
              Saved videos, and search for specific content while customising
              the visual theme.
            </p>
            <a
              className="a-link"
              rel="noreferrer"
              href="https://nxtwatchbb.ccbp.tech/"
              target="_blank"
            >
              <button className="button">View Site</button>
            </a>
          </div>
        </div>
        <div className="project">
          <div className="project-description-container">
            <h1 className="project-sub-heading">Fricipe</h1>
            <p className="project-description-para">
              Fricipe allows users to enter the ingredients they have available
              and then displays a list of possible recipes that use those
              ingredients. The website aims to help users save time, money, and
              food waste by providing them with creative and delicious ways to
              use their ingredients.
            </p>
            <a
              className="a-link"
              rel="noreferrer"
              href="https://fricipebb.ccbp.tech/"
              target="_blank"
            >
              <button className="button">View Site</button>
            </a>
          </div>
          <a
            className="a-link"
            rel="noreferrer"
            href="https://fricipebb.ccbp.tech/"
            target="_blank"
          >
            <img className="project-image" src={fricipe} alt="project-image" />
          </a>
        </div>
        <div className="project">
          <a
            className="a-link"
            rel="noreferrer"
            href="https://tastykitchenbb.ccbp.tech/"
            target="_blank"
          >
            <img className="project-image" src={tasty} alt="project-image" />
          </a>
          <div className="project-description-container">
            <h1 className="project-sub-heading">Tasty Kitchen</h1>
            <p className="project-description-para">
              Tasty Kitchen is a Online Food Ordering System resembling
              Swiggy/Zomato, allowing users to browse popular restaurants, view
              detailed restaurant information, add or remove items from their
              cart, and complete payments.
            </p>
            <a
              className="a-link"
              rel="noreferrer"
              href="https://tastykitchenbb.ccbp.tech/"
              target="_blank"
            >
              <button className="button">View Site</button>
            </a>
          </div>
        </div>
        <div className="project">
          <div className="project-description-container">
            <h1 className="project-sub-heading">Typing Test</h1>
            <p className="project-description-para">
              Typing Test is a website that aims to help you improve your typing
              skills and speed.It mainly focuses on the home row keys (asdfjkl;)
              which are the most frequently used keys in touch typing. By
              practicing on Typing Test, you can learn how to type without
              looking at the keyboard and increase your productivity.
            </p>
            <a
              className="a-link"
              rel="noreferrer"
              href="https://touch-typing-project.vercel.app/"
              target="_blank"
            >
              <button className="button">View Site</button>
            </a>
          </div>
          <a
            className="a-link"
            rel="noreferrer"
            href="https://touch-typing-project.vercel.app/"
            target="_blank"
          >
            <img className="project-image" src={typing} alt="project-image" />
          </a>
        </div>
        <div className="project">
          <a
            className="a-link"
            rel="noreferrer"
            href="https://todolistbb.ccbp.tech/"
            target="_blank"
          >
            <img className="project-image" src={todolist} alt="project-image" />
          </a>
          <div className="project-description-container">
            <h1 className="project-sub-heading">Todos</h1>
            <p className="project-description-para">
              Todos is a simple and effective way to manage your tasks. You can
              easily add, mark, and delete todos with a few clicks.
            </p>
            <a
              className="a-link"
              rel="noreferrer"
              href="https://todolistbb.ccbp.tech/"
              target="_blank"
            >
              <button className="button">View Site</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;

import React from "react";
import { motion } from "framer-motion";

function Skill(props) {
  const { img } = props;
  return (
    <motion.div
      initial={{ y: 10 }}
      animate={{ y: 0 }}
      whileHover={{
        scale: 0.9,
        transition: { duration: 0.1 },
      }}
      className="skill-container"
    >
      <img src={img} className="skill-logo" alt="skill" />
    </motion.div>
  );
}

export default Skill;

import { BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import "./App.css";
import About from "./Components/About";
import Hero from "./Components/Hero";
import Navbar from "./Components/Navbar";
import ScrollToTop from "./Components/ScrollToTop";
import Education from "./Components/Education";
import Footer from "./Components/Footer";
import React from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import RingLoader from "react-spinners/RingLoader";
import Project from "./Components/Project";
import { Contact } from "./Components/Contact";
import { ToastProvider } from "react-toast-notifications";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <BrowserRouter>
      {loading ? (
        <div className="loading-container">
          <ClimbingBoxLoader color="#ffbe2e" />
        </div>
      ) : (
        <>
          <ScrollToTop />
          <Navbar />
          <Hero />
          <About />
          <Education />
          <Project />
          <ToastProvider>
            <Contact />
          </ToastProvider>
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import "./index.css";

function Education() {
  return (
    <section className="education-container" id="education">
      <h1 className="education-heading">EDUCATION</h1>
      <div className="education-sub-container">
        <h1 className="education-degree">Bachelors of Mathematics</h1>
        <p className="education-year">2017-2020</p>
        <p className="education-college">St Xaviers College,Mumbai</p>
      </div>
      <div className="education-sub-container">
        <h1 className="education-degree">Full Stack development</h1>
        <p className="education-year">2022-2023</p>
        <p className="education-college">NxtWave</p>
      </div>
    </section>
  );
}

export default Education;
